import UtilityHelper from '@/helpers/UtilityHelper'

const ERROR_USING_DEFAULT_TRANSLATIONS = 'Locale was not found! Using default translations'
const DEFAULT_LOCALE = 'de-DE'
const ADMIN_LOCALE = 'en-EN'
const FILE_TYPE = '.json'

let translationMemo

const translations = {
  state: {
    locale: '',
    prefix: '',
    translations: {}
  },
  mutations: {
    updateTranslations (state, res) {
      state.translations = res
    },
    setLocale (state, { locale, prefix }) {
      state.locale = locale
      state.prefix = prefix
    }
  },
  actions: {
    async fetchTranslations ({ commit, state }) {
      const { default: SiteConfig } = await import('@/SiteConfig.js')
      let locale = location.href.includes('admin') ? ADMIN_LOCALE : SiteConfig.locale

      if (!locale) {
        console.error(ERROR_USING_DEFAULT_TRANSLATIONS)
        locale = DEFAULT_LOCALE
      }
      commit('setLocale', { locale, prefix: SiteConfig.translationPrefix });

      ({ default: translationMemo } = await import(/* webpackChunkName: "translations/[request]" */
        '../../assets/translations/' + state.locale + FILE_TYPE))
      commit('updateTranslations', translationMemo)
    }
  },
  getters: {
    translationByKey ({ translations, prefix }) {
      return (key, ...data) => {
        const template = translations[prefix + key] ?? translations[key]
        if (!template) return key

        return data.length ? UtilityHelper.formatString(template, ...data) : template
      }
    }
  }
}

export default translations
