import Vue from 'vue'
import Vuex from 'vuex'

import translations from '@/store/modules/translations'
import subscriptions from '@/store/modules/shopify/subscriptions'
import customers from '@/store/modules/admin/customers'
import currentAdmin from '@/store/modules/admin/currentAdmin.js'
import flashMessenger from '@/store/modules/flashMessenger'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    translations,
    subscriptions,
    customers,
    currentAdmin,
    flashMessenger
  }
})
