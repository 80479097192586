import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)

const SubscriptionsPage = () => import(/* webpackChunkName: "subscriptions" */ '@/views/shopify/ShopifySubscriptions.vue')
const ErrorPage = () => import(/* webpackChunkName: "error" */ '@/views/shopify/TheShopifyErrorPage.vue')
const AdminPages = () => import(/* webpackChunkName: "admin-pages" */'@/views/admin/TheAdminPages')
const AdminLoginPage = () => import(/* webpackChunkName: "admin-login" */'@/views/admin/TheAdminLoginPage.vue')

const AdminDashboard = () => import('@/components/admin/dashboard/TheAdminDashboardTemplate')
const AdminCustomerList = () => import('@/components/admin/customer/TheAdminCustomerList')
const AdminCustomerDetails = () => import('@/components/admin/customer/TheAdminCustomerDetails')

const _checkAdmin = (to, from, next) => {
  if (store.getters['currentAdmin/currentAdmin'].email) {
    next()
  } else {
    next({ name: 'Login' })
  }
}

const AdminChildren = [
  { path: '', name: 'Dashboard', component: AdminDashboard },
  { path: 'customers', name: 'Customers', component: AdminCustomerList },
  { path: 'customers/:id', name: 'Customer details', component: AdminCustomerDetails }
]

const routes = [
  { path: '/', name: 'Subscriptions', component: SubscriptionsPage },
  { path: '/admin/', component: AdminPages, children: AdminChildren, beforeEnter: _checkAdmin },
  { path: '/admin/login', name: 'Login', component: AdminLoginPage },
  { path: '/error', name: 'Error', component: ErrorPage }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
