const TRANSACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
}
const EXPIRATION = 2000

const flashMessenger = {
  state: {
    messages: []
  },
  mutations: {
    update (state, { transaction, message }) {
      if (transaction === TRANSACTIONS.ADD) {
        state.messages = [...state.messages, message]
        return
      }
      state.messages = state.messages.filter(({ id }) => id !== message.id)
    }
  },
  actions: {
    add({ commit, dispatch }, content) {
      const id = Math.floor(Math.random() * 100)
      commit('update', { transaction: TRANSACTIONS.ADD, message: { id, content } })
      setTimeout(() => { dispatch('remove', id) }, EXPIRATION)
    },
    remove({ commit }, id) {
      commit('update', { transaction: TRANSACTIONS.REMOVE, message: { id } })
    }
  },
  getters: {
    messages ({ messages }) {
      return messages
    }
  },
  namespaced: true
}

export default flashMessenger
