<template>
  <RouterView/>
</template>

<script>
export default {
  name: 'App',
  metaInfo () {
    return {
      meta: [{ name: 'robots', content: 'noindex,nofollow' }]
    }
  },
  async mounted () {
    const { default: { isWiB } } = await import('@/SiteConfig.js')
    const favicon = document.querySelector('link[rel*="icon"]')
    favicon.href = isWiB ? '/img/icons/favicon-wib-32x32.png' : '/img/icons/favicon-32x32.png'
  }
}
</script>
