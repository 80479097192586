// eslint-disable-next-line replace-relative-imports/replace

const customers = {
  state: {
    activePage: 1,
    totalPages: 0,
    totalCount: 0,
    entities: [],
    lastSearch: ''
  },
  mutations: {
    updateCustomers (state, { totalPages, totalCount, data }) {
      state.totalPages = totalPages
      state.totalCount = data.length ? totalCount : -1
      state.entities = data
    },
    updateTotalCount (state) {
      state.totalCount = 0
    },
    updateLastSearch (state, search) {
      state.lastSearch = search
    },
    updateActivePage (state, page) {
      state.activePage = page
    }
  },
  actions: {
    async fetchCustomers ({ commit }, search = '') {
      const { default: RequestHelper } = await import('@/helpers/RequestHelper')
      const { ADMIN_CUSTOMERS } = await import('@/UrlCollection')
      const { data, headers } = await RequestHelper.sendGetRequest(ADMIN_CUSTOMERS + '?' + search)
      const totalCount = parseInt(headers.get('x-total-count'))
      const totalPages = parseInt(headers.get('x-total-pages'))
      commit('updateCustomers', { totalPages, totalCount, data, search })
    },
    resetFilters ({ dispatch, commit }) {
      commit('updateActivePage', 1)
      commit('updateLastSearch', '')
      commit('updateTotalCount')
      dispatch('fetchCustomers')
    },
    applyFilters ({ state, commit, dispatch }, filters) {
      let search = ''
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          search += `${key}=${value}&`
        }
      })
      search = search.slice(0, -1)

      if (state.lastSearch !== search) {
        commit('updateTotalCount')
        commit('updateLastSearch', search)
        dispatch('fetchCustomers', search)
      }
    },
    changePage ({ state, commit, dispatch }, page) {
      const { lastSearch } = state
      const search = `${lastSearch}${lastSearch ? '&' : ''}page_id=${page}`
      dispatch('fetchCustomers', search)
      commit('updateActivePage', page)
    }
  },
  namespaced: true
}

export default customers
