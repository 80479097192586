const GLOBAL_NEEDLE = /%s/

class UtilityHelper {
  static formatString (subject, ...data) {
    if (!subject) return ''

    if (GLOBAL_NEEDLE.test(subject)) return data.reduce((result, replace) => result.replace(GLOBAL_NEEDLE, replace), subject)

    return data.reduce((result, replace, i) => result.replace(new RegExp('\\{' + i + '\\}', 'gm'), replace), subject)
  }
}

export default UtilityHelper
