import { CONFIG_MAP, CONTACT_MAP, LEGAL_CONFIG } from '@/Sites.js'

const DEV_MODE = process.env.VUE_APP_DEV_MODE === 'ON'

class SiteConfig {
  constructor () {
    this.init()
  }

  init () {
    const config = _selectConfig()

    this.siteId = config.siteId
    this.locale = config.locale
    this.page = config.page
    this.logo = config.logo
    this.phone = config.phone
    this.email = config.email
    this.legals = config.legals
    this.apiDomain = config.apiDomain
    this.siteDomain = config.siteDomain
    this.isWiB = config.isWiB
    this.theme = config.theme
    this.translationPrefix = config.translationPrefix
    this.goldClub = config.goldClub
  }
}

const _selectConfig = () => {
  const hostName = location.hostname
  const domain = _getRef(hostName.substring(hostName.indexOf('.') + 1))
  const siteDomain = hostName.replace(/(^[^.]*.)(\S+)/, 'https://$2')
  const apiDomain =_getApiDomain()
  const config = CONFIG_MAP[domain]
  return { ...config, apiDomain, siteDomain, legals: LEGAL_CONFIG[domain], ...CONTACT_MAP[domain] }
}

const _getApiDomain = () => {
  if (DEV_MODE && process.env.VUE_APP_API_DOMAIN) {
    return process.env.VUE_APP_API_DOMAIN
  }
  return location.hostname.replace(/(^[^.]*)(\S+)/, 'https://api$2/v2/crm/')
}

const _getRef = (domain) => {
  if (DEV_MODE && domain.includes('.test')) {
    // {domain}-{country}.test --> {domain}.{country}
    return domain.replace(/(.*)-(.{2})\.(?=\.*)(.*)/, '$1.$2')
  }

  return domain
}

export default new SiteConfig()
