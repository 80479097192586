export default {
  install (Vue, { GRID_SIZES }) {
    Vue.prototype.$gridSizes = GRID_SIZES
    Vue.mixin({
      methods: {
        translate (key, ...params) {
          return this.$store.getters.translationByKey(key, ...params)
        }
      }
    })
  }
}
