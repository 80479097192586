export const LAST_ADMIN = 'lastAdmin'

const lastLoggedInAdmin = JSON.parse(localStorage.getItem(LAST_ADMIN)) ?? {}

let memo
const _loadDependencies = async () => {
  if (!memo) {
    const { default: RequestHelper } = await import('@/helpers/RequestHelper')
    const {
      ADMIN_LOGIN,
      ADMIN_VERIFY_TWO_FA,
      AUTH_REFRESH
    } = await import('@/UrlCollection')
    memo = {
      RequestHelper,
      UrlCollection: {
        ADMIN_LOGIN,
        ADMIN_VERIFY_TWO_FA,
        AUTH_REFRESH
      }
    }
  }
  return memo
}

const currentAdmin = {
  state: {
    profile: lastLoggedInAdmin
  },
  mutations: {
    refreshState (state, payload = {}) {
      const { access_token: _at, refresh_token: _rt, ...profile } = payload
      state.profile = profile
      localStorage.setItem(LAST_ADMIN, JSON.stringify(profile))
    }
  },
  actions: {
    async login ({ commit }, payload) {
      const { RequestHelper, UrlCollection: { ADMIN_LOGIN } } = await _loadDependencies()
      const { data } = await RequestHelper.sendPostRequest(ADMIN_LOGIN, payload)
      data.last_login_at = new Date().toISOString()
      commit('refreshState', data)
    },
    async verifyTwoFa ({ commit }, payload) {
      const { RequestHelper, UrlCollection: { ADMIN_VERIFY_TWO_FA } } = await _loadDependencies()
      const { data } = await RequestHelper.sendPostRequest(ADMIN_VERIFY_TWO_FA, payload)
      data.last_login_at = new Date().toISOString()
      commit('refreshState', data)
    },
    async logOut ({ commit }) {
      // LATER: make request
      commit('refreshState')
      localStorage.removeItem(LAST_ADMIN)
    },
    async refresh ({ state, commit }) {
      try {
        const { RequestHelper, UrlCollection: { AUTH_REFRESH } } = await _loadDependencies()
        const { data } = await RequestHelper.sendPostRequest(AUTH_REFRESH)
        data.last_login_at = new Date().toISOString()
        commit('refreshState', { ...state.profile, ...data })

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },
  getters: {
    currentAdmin (state) {
      return state.profile
    }
  },
  namespaced: true
}

export default currentAdmin
