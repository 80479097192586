import './polyfills'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/styles/index.scss'
import router from './router'
import store from './store'
import Plugins from './plugins'
import '@/SiteConfig.js'
import Meta from 'vue-meta'

Vue.config.productionTip = false

const GRID_SIZES = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const _getGridBreakpoint = screenWidth => {
  let breakPoint = GRID_SIZES.xl
  const gridSizeKeys = Object.keys(GRID_SIZES)
  gridSizeKeys.some((gridSize, index) => {
    if (screenWidth < GRID_SIZES[gridSizeKeys[index + 1]]) {
      breakPoint = GRID_SIZES[gridSize]
      return true
    }
  })
  return breakPoint
}

new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {
      gridBreakpoint: 0
    }
  },
  async created () {
    await this.$store.dispatch('fetchTranslations')
    this.gridBreakpoint = _getGridBreakpoint(innerWidth)
    addEventListener('resize', () => {
      this.gridBreakpoint = _getGridBreakpoint(innerWidth)
    })
  }
}).$mount('#app')

Vue.use(Plugins, { GRID_SIZES })
Vue.use(Meta)
