let changes = []
let memo

const _getDependencies = async () => {
  if (!memo) {
    const [
      { default: RequestHelper },
      { default: UtilityHelper }
    ] = await Promise.all([
      await import('@/helpers/RequestHelper.js'),
      await import('@/helpers/UtilityHelper.js')
    ])
    memo = { RequestHelper, UtilityHelper }
  }
  return memo
}

const _getUrl = async (admin, email, customerId, hash) => {
  const { UtilityHelper } = await _getDependencies()
  const { ADMIN_SUBSCRIPTION, SUBSCRIPTIONS } = await import('@/UrlCollection')

  if (admin) return UtilityHelper.formatString(ADMIN_SUBSCRIPTION, customerId)

  return UtilityHelper.formatString(SUBSCRIPTIONS, email, hash)
}

const _createConfig = (frequency, possibleFrequency = []) => possibleFrequency.reduce((frequencies, currentFreq) => (
  { ...frequencies, [currentFreq]: currentFreq === frequency }
), {})

const _getLastChanged = (subscribed, unsubscribed) => subscribed > unsubscribed ? subscribed : unsubscribed

const _getTypesForDomain = (wib) => TYPE_MAP.filter(({ ref }) =>
  wib ? ref !== 'newsletter' && ref.indexOf('gold_club') === -1 : ref !== 'deal' && ref !== 'standalone'
)

const _getLastAndEmptyChanges = () => {
  const last = changes.sort().reverse()[0]
  changes = []

  return last
}

const _addChange = (change) => {
  changes = [...changes, change]
}

const TYPE_MAP = [
  { ref: 'newsletter', subRef: 'general', type: 1 },
  { ref: 'notification', subRef: 'abandoned_cart', type: 2 },
  { ref: 'notification', subRef: 'customer_benefit', type: 3 },
  { ref: 'notification', subRef: 'recommendation', type: 4 },
  { ref: 'notification', subRef: 'follow_up', type: 5 },
  { ref: 'notification', subRef: 'rating', type: 6 },
  { ref: 'gold_club_offer', subRef: 'general', type: 7 },
  { ref: 'gold_club_highlight', subRef: 'general', type: 8 },
  { ref: 'deal', subRef: 'general', type: 9 },
  { ref: 'standalone', subRef: 'general', type: 10 }
]

const subscriptions = {
  state: {
    defaults: {
      newsletter: ['weekly'],
      deal: ['daily'],
      notification: ['follow_up', 'recommendation', 'abandoned_cart', 'customer_benefit', 'rating'],
      gold_club_offer: ['weekly_twice'],
      gold_club_highlight: ['weekly'],
      standalone: ['daily']
    },
    newsletter: {
      general: false,
      weekly: false,
      monthly: false,
      occasionally: false,
      frequency: '',
      updated_at: ''
    },
    deal: {
      general: false,
      weekly: false,
      monthly: false,
      daily: false,
      frequency: '',
      updated_at: ''
    },
    standalone: {
      general: false,
      weekly: false,
      weekly_twice: false,
      daily: false,
      frequency: '',
      updated_at: ''
    },
    notification: {
      general: false,
      follow_up: false,
      recommendation: false,
      abandoned_cart: false,
      customer_benefit: false,
      rating: false,
      updated_at: ''
    },
    goldclub_general: {
      available: true,
      disabled: false,
      updated_at: ''
    },
    gold_club_offer: {
      general: false,
      weekly: false,
      monthly: false,
      weekly_twice: false,
      frequency: ''
    },
    gold_club_highlight: {
      general: false,
      weekly: false,
      monthly: false,
      occasionally: false,
      frequency: ''
    },
    global: {
      subscribed: false,
      unsubscribed_at: '',
      has_subscriptions: true
    }
  },
  mutations: {
    refreshState(state, payload) {
      const unsubscribes = []
      if (payload.newsletter?.length) {
        payload.newsletter.forEach(({ frequency, subscribed, unsubscribed_at, subscribed_at }) => {
          if (unsubscribed_at !== null) unsubscribes.push(unsubscribed_at)
          const possibleFreq = ['weekly', 'monthly', 'occasionally']
          _addChange(_getLastChanged(subscribed_at, unsubscribed_at))
          state.newsletter = { ..._createConfig(frequency, possibleFreq), general: !!subscribed, frequency }
        })
        state.newsletter.updated_at = _getLastAndEmptyChanges()
      }

      if (payload.deal?.length) {
        payload.deal.forEach(({ frequency, subscribed, unsubscribed_at, subscribed_at }) => {
          if (unsubscribed_at !== null) unsubscribes.push(unsubscribed_at)
          const possibleFreq = ['daily', 'weekly', 'monthly']
          _addChange(_getLastChanged(subscribed_at, unsubscribed_at))
          state.deal = { ..._createConfig(frequency, possibleFreq), general: !!subscribed, frequency }
        })
        state.deal.updated_at = _getLastAndEmptyChanges()
      }

      if (payload.standalone?.length) {
        payload.standalone.forEach(({ frequency, subscribed, unsubscribed_at, subscribed_at }) => {
          if (unsubscribed_at !== null) unsubscribes.push(unsubscribed_at)
          const possibleFreq = ['daily', 'weekly', 'weekly_twice']
          _addChange(_getLastChanged(subscribed_at, unsubscribed_at))
          state.standalone = { ..._createConfig(frequency, possibleFreq), general: !!subscribed, frequency }
        })
        state.standalone.updated_at = _getLastAndEmptyChanges()
      }

      payload.notification.forEach(({ email_code: code, subscribed, unsubscribed_at, subscribed_at }) => {
        if (unsubscribed_at !== null) unsubscribes.push(unsubscribed_at)
        state.notification.general = !!subscribed
        state.notification[code] = !!subscribed
        _addChange(_getLastChanged(subscribed_at, unsubscribed_at))
      })
      state.notification.updated_at = _getLastAndEmptyChanges()

      if (payload.goldclub) {
        payload.goldclub.forEach(({ email_code: code, frequency, subscribed, unsubscribed_at, subscribed_at }) => {
          if (unsubscribed_at !== null) unsubscribes.push(unsubscribed_at)
          const possibleFreq = ['weekly', 'weekly_twice', 'monthly', 'occasionally']
          _addChange(_getLastChanged(subscribed_at, unsubscribed_at))
          state[code] = { ..._createConfig(frequency, possibleFreq), general: !!subscribed, frequency }
        })

        state.goldclub_general.updated_at = _getLastAndEmptyChanges()
        state.goldclub_general.available = true
        state.global.subscribed = state.newsletter.general || state.notification.general ||
          state.gold_club_offer.general || state.gold_club_highlight.general
      } else {
        state.goldclub_general.available = false
        state.global.subscribed = state.newsletter.general || state.notification.general
      }

      state.global.unsubscribed_at = unsubscribes.toSorted((a, b) => a - b).reverse()[0]
    },
    resetGroup(state, { group, skipKey, setDefaults }) {
      if (setDefaults) state[group].general = true
      Object.keys(state[group]).forEach(key => {
        if (key === skipKey || key === 'updated_at') return
        if (setDefaults && state.defaults[group].includes(key)) {
          state[group][key] = true
          if (group !== 'notification') state[group].frequency = key
          return
        }
        if (key !== 'frequency') state[group][key] = false
      })
    },
    refreshGeneral(state, group) {
      let general = false
      if (group === 'notification') {
        Object.keys(state[group]).forEach(key => {
          if (key === 'general' || key === 'frequency' || key === 'updated_at') return
          if (state[group][key]) general = true
        })
      }
      state[group].general = general
    },
    updateSubscriptionState(state, { group, type, value }) {
      state[group][type] = value
      if (group !== 'notification' && group !== 'goldclub_general') {
        state[group].frequency = type !== 'general' ? type : ''
      } else if (group === 'goldclub_general' && value) {
        state.gold_club_offer.frequency = null
        state.gold_club_highlight.frequency = null
      }
    },
    setHasSubscriptions(state, value) {
      state.global.has_subscriptions = value
    }
  },
  actions: {
    resetSubscriptionGroup({ commit }, { group, skipKey = 'general', setDefaults = false }) {
      commit('resetGroup', { group, skipKey, setDefaults })
    },
    async fetchSubscriptions({ commit, dispatch }, { email, customerId, hash, admin }) {
      const { RequestHelper } = await _getDependencies()

      const url = await _getUrl(admin, email, customerId, hash)

      try {
        const { data } = await RequestHelper.sendGetRequest(url)
        commit('refreshState', data)
        commit('setHasSubscriptions', true)
      } catch (e) {
        dispatch('flashMessenger/add', 'loading_error', { root: true })
        commit('setHasSubscriptions', false)
      }
    },
    async saveSubscriptions({ state, commit, dispatch }, { email, customerId, hash, admin, wib }) {
      const { RequestHelper } = await _getDependencies()
      const url = await _getUrl(admin, email, customerId, hash)
      const types = _getTypesForDomain(wib)

      const subscriptionsData = types.reduce((subscriptions, { ref, subRef, type }, i) => {
        const subscribed = state[ref][subRef]
        const frequency = ref !== 'notification' ? state[ref].frequency : null

        // if gold club is not available for customer, omit those email types
        if (!state.goldclub_general.available && ref.indexOf('gold_club') !== -1) {
          return subscriptions
        }
        // gold club is available or general email type
        return { ...subscriptions, [i]: { subscribed, frequency, email_type_id: type } }
      }, {})
      try {
        const { data } = await RequestHelper.sendPutRequest(url, [subscriptionsData])
        commit('refreshState', { ...state, ...data })
      } catch (e) {
        dispatch('flashMessenger/add', 'saving_error', { root: true })
      }
    },
    async updateSubscriptionType({ dispatch, commit }, { group, type, value, resetGroup = true }) {
      const setDefaults = value && type === 'general'
      if (value && resetGroup) dispatch('resetSubscriptionGroup', { group, setDefaults })
      if (!setDefaults) commit('updateSubscriptionState', { group, type, value })
      if (!value) commit('refreshGeneral', group)
    },
    async unsubscribe({ commit, state, dispatch }, customerId) {
      const { RequestHelper, UtilityHelper } = await _getDependencies()
      const { ADMIN_SUBSCRIPTION } = await import('@/UrlCollection')

      const url = UtilityHelper.formatString(ADMIN_SUBSCRIPTION, customerId)
      try {
        const { data } = await RequestHelper.sendPutRequest(url, { global: false })
        commit('refreshState', { ...state, ...data })
      } catch (e) {
        dispatch('flashMessenger/add', 'saving_error', { root: true })
      }
    }
  },
  namespaced: true
}

export default subscriptions
